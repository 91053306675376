import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { GLOBAL_COLORS } from 'shared/constants/COLORS';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

interface ResponsiveProp {
  desktop: string | number;
  tablet: string | number;
  mobile: string | number;
}

interface DesktopLayoutProps {
  desktopHide?: boolean;
  width: any;
  grow?: ResponsiveProp;
  padding: string;
  shrink?: boolean;
  align?: ResponsiveProp;
  justify?: ResponsiveProp;
  column?: boolean;
}

const DesktopLayout = {
  Cell: css<DesktopLayoutProps>`
    display: ${({ desktopHide }) => (desktopHide ? 'none' : null)};
    width: ${({ width }) => width.desktop || width};
    flex-grow: ${({ grow }) => (grow ? grow.desktop : null)};
    padding: ${({ padding }) => padding};
    flex-shrink: ${({ shrink }) => (shrink ? '1' : null)};
    align-items: ${({ align }) => (align ? align.desktop : 'center')};
    justify-content: ${({ justify }) => (justify ? justify.desktop : 'flex-start')};
    flex-direction: ${({ column }) => (column ? 'column' : null)};

    ${({ desktopHide }) =>
      desktopHide &&
      css`
        @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
          display: flex;
        }
      `}
  `,
};

interface TabletLayoutProps {
  tabletHide?: boolean;
  width: any;
  align?: ResponsiveProp;
  justify?: ResponsiveProp;
  column?: boolean;
  tabletRow?: boolean;
  tabletWrap?: boolean;
  shrink?: boolean;
  grow?: ResponsiveProp;
  tabletHeight?: string;
  tabletPadding?: string;
  margin?: ResponsiveProp;
  tabletBorderHide?: boolean;
  order?: ResponsiveProp;
}

const TabletLayout = {
  Cell: css<TabletLayoutProps>`
    display: ${({ tabletHide }) => tabletHide && 'none'};
    width: ${({ width }) => width.tablet || width};
    align-items: ${({ align }) => (align ? align.tablet : null)};
    justify-content: ${({ justify }) => (justify ? justify.tablet : 'flex-start')};
    flex-direction: ${({ column }) => (column ? 'column' : null)};
    flex-direction: ${({ tabletRow }) => (tabletRow ? 'row' : null)};
    flex-wrap: ${({ tabletWrap }) => (tabletWrap ? 'wrap' : null)};
    flex-shrink: ${({ shrink }) => (shrink ? '1' : null)};
    flex-grow: ${({ grow }) => (grow ? grow.tablet : null)};
    min-height: ${({ tabletHeight }) => tabletHeight || null};
    padding: ${({ tabletPadding }) => tabletPadding || null};
    margin: ${({ margin }) => (margin ? margin.tablet : null)};
    border: ${({ tabletBorderHide }) => (tabletBorderHide ? 'none' : null)};
    order: ${({ order }) => (order ? order.tablet : null)};
  `,
};

interface MobileLayoutProps {
  mobileHide?: boolean;
  width?: ResponsiveProp;
  align?: ResponsiveProp;
  justify?: ResponsiveProp;
  mobileRow?: boolean;
  mobileWrap?: boolean;
  grow?: ResponsiveProp;
  mobileHeight?: string;
  mobilePadding?: string;
  margin?: ResponsiveProp;
  mobileBorderHide?: boolean;
  order?: ResponsiveProp;
}

const MobileLayout = {
  Cell: css<MobileLayoutProps>`
    display: ${({ mobileHide }) => mobileHide && 'none'};
    width: ${({ width }) => (width ? width.mobile : null)};
    align-items: ${({ align }) => (align ? align.mobile : null)};
    justify-content: ${({ justify }) => (justify ? justify.mobile : null)};
    flex-direction: ${({ mobileRow }) => (mobileRow ? 'row' : null)};
    flex-wrap: ${({ mobileWrap }) => (mobileWrap ? 'wrap' : null)};
    flex-grow: ${({ grow }) => (grow ? grow.mobile : null)};
    min-height: ${({ mobileHeight }) => mobileHeight || null};
    padding: ${({ mobilePadding }) => mobilePadding || null};
    margin: ${({ margin }) => (margin ? margin.mobile : null)};
    border: ${({ mobileBorderHide }) => (mobileBorderHide ? 'none' : null)};
    order: ${({ order }) => (order ? order.mobile : null)};
  `,
};

interface ContainerProps {
  tableDisabled?: boolean;
}

interface HeadProps {
  isFixed?: boolean;
  switchToTablet?: boolean;
  inModal?: boolean;
}

interface DividerProps {
  switchToTablet?: boolean;
  padding?: string;
  order?: string | number;
  mobileHide?: boolean;
}

export interface CellProps {
  heading?: boolean;
  maxHeight?: string;
  switchToTablet?: boolean;
  bold?: boolean;
  gray?: boolean;
  fontSize?: string;
  breakAll?: boolean;
  pt?: string;
  pb?: string;
  align?: any;
  justify?: any;
  column?: boolean;
  width?: string | object;
  tabletPadding?: string;
  margin?: any;
  padding?: any;
  tabletHeight?: string;
  tabletRow?: boolean;
  mobilePadding?: string;
  shrink?: boolean;
}

interface DisableOverlayProps {
  isOpen?: boolean;
}

interface CheckboxCellProps {
  padding?: string;
  width?: any;
  isError?: boolean;
  switchToTablet?: boolean;
}

interface SmallDescriptionProps {
  withMobileStyles?: boolean;
}

interface PurchasedDateProps {
  switchToTablet?: boolean;
}

interface TicketsInfoProps {
  right?: boolean;
  tabletBetween?: boolean;
}

interface TicketsLabelsProps {
  tabletShow?: boolean;
  tabletVisible?: boolean;
}

interface MobileContainerProps {
  width?: string;
  margin?: string;
}

interface LabelProps {
  margin?: string;
  width?: string;
  bold?: boolean;
  desktopHide?: boolean;
}

interface ValueProps {
  offsetRight?: string;
  bold?: boolean;
  mobileVisible?: boolean;
}

interface DesktopContainerProps {
  mobileVisible?: boolean;
}

interface AnnouncementTimeProps {
  mobileBlack?: boolean;
}

export default {
  Container: styled.div<ContainerProps>`
    display: flex;
    flex-grow: 1;

    ${({ tableDisabled }) =>
      tableDisabled &&
      css`
        position: relative;
        overflow: hidden;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          z-index: 11;
          opacity: 0.64;
          cursor: not-allowed;
        }
      `}
  `,

  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    flex-grow: 1;
  `,

  Table: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;

    &:after {
      content: '';
      display: block;
      min-height: 16px;
      flex-basis: 16px;
      height: 16px;
    }

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      padding: 0 16px;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 0;
    }
  `,

  Head: styled.div<HeadProps>`
    display: flex;
    align-items: center;
    min-height: 40px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fafafa;
    padding-left: 8px;
    flex-shrink: 0;
    box-shadow: ${({ isFixed }) => (isFixed ? '0px 4px 8px rgba(0, 0, 0, 0.08)' : null)};

    ${({ switchToTablet }) =>
      switchToTablet &&
      css`
        border-radius: 0;
        flex-grow: 1;
        padding: 0 8px;
      `}

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      display: ${({ inModal }) => (inModal ? 'none' : null)};
      border-radius: 0;
      flex-grow: 1;
      padding: 0 8px;
    }
  `,

  HeadTitle: styled.div`
    display: flex;
    flex-shrink: 0;
  `,

  Body: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
  `,

  Divider: styled.div<DividerProps>`
    position: relative;
    display: ${({ switchToTablet }) => (switchToTablet ? 'block' : 'none')};
    width: 100%;
    height: 1px;
    padding: ${({ padding }) => padding || '3px 0 8px'};
    order: ${({ order }) => order || null};

    &:after {
      position: absolute;
      content: '';
      display: block;
      left: 8px;
      right: 8px;
      height: 1px;
      background-color: #e1e1e1;
    }

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      display: block;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: ${({ mobileHide }) => mobileHide && 'none'};
    }
  `,

  Cell: styled.div<CellProps>`
    padding: 8px;
    display: flex;
    font-size: 14px;
    color: #000;
    box-sizing: border-box;
    flex-shrink: 0;
    min-height: ${({ heading, maxHeight }) => !heading && !maxHeight && '56px'};
    max-height: ${({ maxHeight }) => maxHeight};
    margin: ${({ margin }) => margin};

    ${({ switchToTablet }) => (switchToTablet ? TabletLayout.Cell : DesktopLayout.Cell)}

    & > img {
      margin-right: 8px;
    }

    & > svg {
      margin-right: 8px;
    }

    @media (min-width: ${BREAKPOINTS.LAPTOP}) {
      font-size: 16px;
    }

    /* bold */
    font-weight: ${({ bold }) => bold && 'bold'};

    /* gray */
    color: ${({ gray }) => gray && '#999'};

    /* fontSize */
    font-size: ${({ fontSize }) => fontSize && fontSize};

    /* breakAll */
    word-break: ${({ breakAll }) => breakAll && 'break-all'};

    /* pt */
    padding-top: ${({ pt }) => pt && pt};

    /* pb */
    padding-bottom: ${({ pb }) => pb && pb};

    /* align */
    justify-content: ${({ align }) => {
      switch (align) {
        case 'center':
          return 'center';

        case 'right':
          return 'flex-end';

        default:
          break;
      }

      return null;
    }};

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      min-height: 40px;
      ${TabletLayout.Cell}
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      ${MobileLayout.Cell}
    }
  `,

  CheckboxCell: styled.div<CheckboxCellProps>`
    position: relative;
    padding: ${({ padding }) => padding || '8px'};
    width: ${({ width }) => width.desktop || width};
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 56px;
    flex-shrink: 0;
    cursor: default;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 20px;
      left: -20px;
    }

    ${({ isError }) =>
      isError &&
      css`
        & input:checked + span {
          background-color: #eb5757 !important;
          border-color: #eb5757 !important;
          background-image: url('/static/icons/exclamation-white.svg');
        }
      `}

    ${({ switchToTablet }) => (switchToTablet ? TabletLayout.Cell : DesktopLayout.Cell)}

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      min-height: 40px;
      ${TabletLayout.Cell}
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      ${MobileLayout.Cell}
    }
  `,

  Title: styled.div`
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      font-size: 16px;
      line-height: 21px;
    }
  `,

  SmallDescription: styled.div<SmallDescriptionProps>`
    width: 100%;
    margin-top: 2px;
    font-size: 12px;
    color: #5c5c5c;
    display: flex;
    align-items: center;

    & > img {
      margin-right: 8px;
    }

    ${({ withMobileStyles }) =>
      withMobileStyles &&
      css`
        @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
          display: inline;
          margin: 0 0 0 5px;
          padding-left: 5px;
          color: inherit;
          width: auto;
          font-size: inherit;
          border-left: 1px solid #000;
        }
      `}
  `,

  PurchasedDate: styled.div<PurchasedDateProps>`
    width: 100%;
    margin-top: 2px;
    font-size: 12px;
    color: #5c5c5c;
    display: flex;
    align-items: center;

    & > img {
      margin-right: 8px;
    }

    ${({ switchToTablet }) =>
      switchToTablet &&
      css`
        flex-shrink: 0;
        width: auto;
        margin: 0 0 0 8px;
        font-size: 12px;
        line-height: 16px;
      `}

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      flex-shrink: 0;
      width: auto;
      margin: 0 0 0 8px;
      font-size: 12px;
      line-height: 16px;
    }
  `,

  Th: styled.div`
    font-size: 12px;
    color: #999;
    font-weight: bold;
  `,

  TicketsInfo: styled.div<TicketsInfoProps>`
    display: flex;
    align-items: center;
    justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
    flex-wrap: wrap;
    white-space: nowrap;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      justify-content: ${({ tabletBetween }) => (tabletBetween ? 'space-between' : 'flex-start')};
      flex-wrap: nowrap;
      width: 100%;
    }
  `,

  TicketsLabels: styled.div<TicketsLabelsProps>`
    display: ${({ tabletShow }) => (tabletShow ? 'none' : 'flex')};
    align-items: center;

    & > div {
      margin-right: 4px;
      display: flex;
      align-items: center;
    }

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      display: ${({ tabletShow, tabletVisible }) => (tabletShow || tabletVisible ? 'flex' : 'none')};
      margin-right: 4px;
    }
  `,

  MobileContainer: styled.div<MobileContainerProps>`
    display: none;
    width: ${({ width }) => width};
    margin: ${({ margin }) => margin || '0 0 12px'};

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      display: flex;
    }
  `,

  Label: styled.div<LabelProps>`
    margin: ${({ margin }) => margin || '4px 0 0'};
    flex-shrink: 0;
    width: ${(props) => props.width};
    font-weight: ${(props) => (props.bold ? '700' : '400')};
    font-size: 12px;
    color: #5c5c5c;

    ${({ desktopHide }) =>
      desktopHide &&
      css`
        display: none;

        @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
          display: block;
        }
      `}
  `,

  Value: styled.div<ValueProps>`
    font-size: 16px;
    color: #000;
    padding-right: ${({ offsetRight }) => offsetRight || null};

    ${(props) =>
      props.bold &&
      css`
        font-weight: 700;
      `}
  `,

  DesktopContainer: styled.div<DesktopContainerProps>`
    max-width: 100%;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      display: ${(props) => (props.mobileVisible ? 'block' : 'none')};
      margin-bottom: 12px;
    }
  `,

  Pagination: styled.div`
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    margin-top: 40px;
  `,

  Burned: styled.div`
    color: #eb5757;
    font-size: 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;

    & > svg {
      margin-left: 8px;
    }
  `,

  DisableOverlay: styled.div<DisableOverlayProps>`
    position: absolute;
    top: -42px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    cursor: not-allowed;

    opacity: ${({ isOpen }) => isOpen && 0.8};
    pointer-events: ${({ isOpen }) => isOpen && 'all'};
  `,

  Place: styled.div`
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      font-size: 12px;
    }
  `,

  PlaceAddress: styled.div`
    margin-top: 2px;
    font-size: 12px;
    color: ${GLOBAL_COLORS.colorTextDescription};
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 22px;
    font-weight: 500;


    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      display: inline;
      position: relative;
      margin-top: 0;

      &:before {
        position: relative;
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 5px;
        background-color: #5c5c5c;
        vertical-align: middle;
        border-radius: 50%;
      }
    }
  `,

  Category: styled.div`
    max-width: 100%;

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 16px;
      color: #5c5c5c;
    }
  `,

  Time: styled.div`
    width: 100%;
    max-width: 50px;
  `,

  AnnouncementTime: styled.time<AnnouncementTimeProps>`
    font-size: 14px;
    color: #5c5c5c;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      color: ${({ mobileBlack }) => mobileBlack && '#000'};
    }
  `,

  Counter: styled.div`
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    & > span {
      color: #5c5c5c;
    }
  `,

  FloatDropdown: styled.div`
    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      position: absolute;
      top: 0;
      right: 0;
    }
  `,

  DateInfo: styled.div`
    display: flex;
    align-items: center;
    min-height: 32px;
  `,

  EmptyTickets: styled.div`
    width: 100%;
    color: #5c5c5c;
    display: flex;
    justify-content: center;
  `,

  PriceInputValue: styled.div`
    padding: 10px;

    & > span {
      color: #5c5c5c;
    }

    @media ${MEDIA_QUERIES.MOBILE_AND_TABLET} {
      padding: 0;
    }
  `,

  Arrow: styled.div`
    width: 100%;
    height: 40px;
    background-image: url('/static/icons/chevron-right.svg');
    background-position: center;
    background-repeat: no-repeat;
  `,

  Date: styled.div`
    font-size: 14px;
    color: #5c5c5c;
  `,
};
